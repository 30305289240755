<div class="modal-backdrop" style="background-color: #0000007a;"></div>
<div class="modal modal-2 animated fadeInDown" tabindex="-1" role="dialog" style="display: block; overflow-y: auto;">
	<div class="modal-dialog modal-login modal-dialog-centered" role="document">
		<div class="modal-content modal-login">
			<div class="d-flex justify-content-center">
				<button class="btn btn-rounded btn-rounded-close" (click)="close()">
					<i class="fa fa-times" aria-hidden="true"></i>
				</button>
			</div>
			<div class="modal-body">
				<form [formGroup]="formLogin">
					<div class="row">
						<div class="col-12 text-center">
							<img src="assets/banner.png" style="width: 12rem;">
						</div>
					</div>
					<div class="row" style="margin-top: 10px;">
						<div class="col-12 text-center" style="font-size: 0.8rem;">
							<span class="text-secondary">Bienvenidos! Favor entrar con tu cuenta.</span>
						</div>
					</div>

					<div class="row" style="margin-top: 10px;">
						<div class="col-12 text-center">
							<h3 class="text-secondary font-weight-bolder">Iniciar Sesión</h3>
						</div>
					</div>

					<div class="row">
						<div class="col-12 form-label-group">
							<input type="text" id="inputEmail" formControlName="email"
								class="form-control form-control-lg" placeholder="Correo electrónico"
								(focus)="clearMessage()">
							<label for="inputEmail">Correo electrónico</label>
							<span class="text-danger invalid-control"
								*ngIf="formLogin.controls.email.invalid && formLogin.controls.email.dirty">
								Campo email o usuario requerido.
							</span>
						</div>
					</div>

					<div class="row">
						<div class="col-12 form-label-group">
							<input type="password" id="inputPassword" formControlName="password"
								class="form-control form-control-lg" (focus)="clearMessage()" placeholder="Contraseña">
							<label for="inputPassword">Contraseña</label>
							<span class="text-danger invalid-control"
								*ngIf="formLogin.controls.password.invalid && formLogin.controls.password.dirty">
								Campo contraseña requerido.
							</span>
						</div>
					</div>

					<div class="row">
						<!-- 
						<div class="col form-group">
							<div class="custom-control custom-checkbox">
								<input type="checkbox" class="custom-control-input" id="customCheck1">
								<label class="custom-control-label" for="customCheck1">Recuérdame</label> 
							</div>
						</div>-->
						<div class="col-auto form-group">
							<!-- <a routerLink="./" > -->
							<!-- <a [routerLink]="['./home', {outlets: {modal: 'password-recovery'}}]"> -->
							<a routerLink="../password-recovery">
								¿Olvidaste tu contraseña?
							</a>
						</div>
					</div>

					<div class="row">
						<div class="col-12 form-group">
							<span class="text-danger">
								{{ errorMessage }}
							</span>
						</div>
					</div>

					<div class="row justify-content-center">
						<div class="col-6">
							<button type="submit" [disabled]="_loading" class="btn btn-block btn-ok btn-ok--blue"
								(click)="logIn()">
								<i *ngIf="_loading" class="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i>
								Iniciar sesión
							</button>
						</div>
					</div>
				</form>
			</div>
			<div class="modal-body bg-light" style="padding-top: 5px;">
				<!--
				<ng-container *ngIf="!_businessProfile">
					<div class="row">
						<div class="col-12 text-center">
							<h3 class="text-secondary font-weight-bolder">O ingresa con</h3>
						</div>
					</div>
	
					<div class="row justify-content-center">
						<div class="col text-right form-group">
							<img src="assets/facebook.png" style="width: 4rem;">
						</div>
						<div class="col text-left form-group">
							<img src="assets/google-plus.png" style="width: 4rem;">
						</div>
					</div>
				</ng-container>
				 -->

				<div class="row justify-content-center">
					<div class="col-auto form-group" style="font-size: 0.9rem;">
						<span class="text-secondary">¿No tienes cuenta?</span>
					</div>
				</div>

				<div class="row justify-content-center mb-3">
					<div class="col-auto">
						<a class="btn btn-outline-secondary" routerLink="../register">Regístrate</a>
					</div>
				</div>

				<div class="row justify-content-center">
					<div class="col-auto form-group" style="font-size: 0.9rem;">
						<span class="text-secondary">
							<a href="{{docsApp.usagePolicies}}" target="_BLANCK">Política de uso</a> y los
							<a href="{{docsApp.terms}}" target="_BLANCK">términos y condiciones</a>
						</span>
					</div>
				</div>
			</div>
			<div class="modal-body" style="padding-top: 5px;" *ngIf="_businessProfile">
				<div class="row">
					<div class="col text-center">
						<span class="text-muted" style="font-size: 0.5rem;">
							Oportunidapp esuna plataforma desarrollada por el Grupo Logis - Logística Laboral S.A.S y
							respaldada por:
						</span>
					</div>
				</div>

				<div class="row">
					<div class="col text-center">
						<img src="assets/logis_group.png" style="width: 100%;">
					</div>
				</div>
			</div>
		</div>
	</div>
</div>