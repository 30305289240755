<div class="row">
  <div class="col-12">
    <h5 class="font-weight-semi-bold">{{ title }}</h5>
    <hr />
  </div>

  <div class="col-12">
    <form class="row" [formGroup]="successCaseForm" (ngSubmit)="save()">
      <!-- PICTURE -->
      <div class="col-12">
        <div class="row">
          <div
            class="col-12 img-fluid image-contain-picture"
            [style.background]="
              'url(' +
              utils.getImgSrc(successCaseForm.get('images').value) +
              ')'
            "
          >
            <input
              type="file"
              (change)="setFile($event)"
              id="inputUpload"
              #inputFile
              accept="image/*"
              style="display: none;"
            />
          </div>

          <div class="col-12 mt-2">
            <button
              type="button"
              (click)="inputFile.click()"
              class="btn bg-light-gray text-white"
            >
              Seleccionar imagen
            </button>
          </div>
        </div>
      </div>

      <!-- NAME -->
      <div class="col-12 col-md-6 form-group">
        <label class="font-weight-bold small">Nombre*</label>
        <input
          type="text"
          class="form-control input-3"
          formControlName="name"
        />
        <field-error [fcontrol]="successCaseForm.get('name')"></field-error>
      </div>

      <!-- VIDEO -->
      <div class="col-12 col-md-6 form-group">
        <label class="font-weight-bold small">Link video youtube*</label>
        <input
          type="text"
          class="form-control input-3"
          formControlName="video"
        />
        <field-error [errorsMsg]="urlCustomError" [fcontrol]="successCaseForm.get('video')"></field-error>
      </div>

      <!-- COMMENT -->
      <div class="col-12 form-group">
        <label class="font-weight-bold small">Comentario*</label>
        <textarea
          rows="4"
          class="form-control input-3"
          formControlName="comment"
        ></textarea>
        <field-error [fcontrol]="successCaseForm.get('comment')"></field-error>
      </div>
      
      <!-- ROLE -->
      <div class="col-12 form-group">
        <label class="font-weight-bold small" >Caso de éxito para:</label>
        <select formControlName="rol" class="form-control input-3">
            <option disabled [value]="null">Seleccionar</option>
            <option *ngFor="let item of roles" [value]="item.id">{{item.name}}</option>
        </select>
      </div>
      

      <div class="col-12 mt-4">
        <div class="row justify-content-center">
          <div class="col-6">
            <button
              type="submit"
              [disabled]="successCaseForm.invalid || (submitting$ | async)"
              class="btn btn-ok--blue btn-block"
            >
              <ng-container *ngIf="!(submitting$ | async); else saving">
                Guardar
              </ng-container>
              <ng-template #saving>
                Guardando
                <i
                  class="fa fa-circle-o-notch fa-spin text-white"
                  aria-hidden="true"
                ></i>
              </ng-template>
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
