<div class="row" [ngClass]="class? class:''">

  <div class="col-auto d-flex align-items-center" *ngIf="backButton">
    <button (click)="goBack()" class="btn btn-ok--blue back-button">
      <i class="fa fa-arrow-left back-button-icon" aria-hidden="true"></i>
    </button>
  </div>

  <div class="col-auto d-flex align-items-center">
    <h2 class="page-header">{{header}}</h2>
  </div>

  <div [class]="'col '+classes">
    <ng-content></ng-content>
  </div>
</div>
