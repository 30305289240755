<div class="col-md-12">
	<h6>Requerimientos</h6>
	<ul style="list-style: none; padding-left: 0px;">
		<li>
			<span [ngClass]="validatePWrequirement_1 ? 'pwValid' : 'pwInvalid'">
				<i *ngIf="validatePWrequirement_1" class="fa fa-check-circle-o" aria-hidden="true"></i>
				<i *ngIf="!validatePWrequirement_1" class="fa fa-times-circle-o" aria-hidden="true"></i>
				Al menos un número
			</span>
		</li>
		<li>
			<span [ngClass]="validatePWrequirement_2 ? 'pwValid' : 'pwInvalid'">
				<i *ngIf="validatePWrequirement_2" class="fa fa-check-circle-o" aria-hidden="true"></i>
				<i *ngIf="!validatePWrequirement_2" class="fa fa-times-circle-o" aria-hidden="true"></i>
				Al menos una letra
			</span>
		</li>
		<li>
			<span [ngClass]="validatePWrequirement_3 ? 'pwValid' : 'pwInvalid'">
				<i *ngIf="validatePWrequirement_3" class="fa fa-check-circle-o" aria-hidden="true"></i>
				<i *ngIf="!validatePWrequirement_3" class="fa fa-times-circle-o" aria-hidden="true"></i>
				Sin espacios en blanco
			</span>
		</li>
		<li>
			<span [ngClass]="validatePWrequirement_4 ? 'pwValid' : 'pwInvalid'">
				<i *ngIf="validatePWrequirement_4" class="fa fa-check-circle-o" aria-hidden="true"></i>
				<i *ngIf="!validatePWrequirement_4" class="fa fa-times-circle-o" aria-hidden="true"></i>
				Mínimo 6 caracteres
			</span>
		</li>
	</ul>
</div>