<div class="home-section bg-oportunidapp-light">
    <div class="container">
        <app-vacancies-section></app-vacancies-section>
    </div>
</div>

<div class="home-section">
    <div class="container">
        <app-posts-section></app-posts-section>
    </div>
</div>

<div class="home-section bg-oportunidapp-blue">
    <div class="container">
        <div class="row mb-4">
            <div class="col text-center text-white">
                <h3>Últimos casos de éxito</h3>

                <span style="max-width: 50px;">
                    Conoce algunos de nuestros casos de éxito que han pasado por nuestro <br> proceso de selección
                </span>
            </div>
        </div>
        <app-carousel-success-stories></app-carousel-success-stories>

        <div class="row mt-5">
            <div class="col text-center">
                <button class="btn btn btn-outline-light" routerLink="./success-cases/succeeds">
                    Ver más
                </button>
            </div>
        </div>
    </div>
</div>

<div class="home-section bg-oportunidapp-light">
    <div class="container">
        <app-alliances-section></app-alliances-section>
    </div>
</div>