<div class="bg-oportunidapp-light-two">
  <div class="container">
    <div class="col-12">
      <h1 class="section-title" style="padding-top: 60px;">Beneficios</h1>
    </div>
    <!-- Titulo del componente -->

    <div class="row col-one">
      <div class="col-md-6">
        <img class="image" src="../../../assets/empty.jpg">
      </div>
      <div class="col-md-6">
        <div class="row">
          <div class="col-12">
            <h1>Our Team</h1>
            <p>Lorem ipsum dolor sit amet consectetur adipiscing elit neque, ut nibh luctus dui habitant fermentum metus
              turpis himenaeos, vitae dapibus malesuada vehicula bibendum sociosqu posuere. Lacus primis ultrices
              vestibulum ac fringilla per congue, montes curae id augue ut condimentum, diam platea tristique ridiculus
              magna hendrerit. Urna aliquet torquent viverra netus pellentesque dui curabitur diam sed curae, litora
              habitant rutrum erat ad in cursus fringilla faucibus interdum, aliquam elementum id dapibus augue potenti
              aenean platea quisque.</p>
          </div>

        </div>
        <span><b>Lorem ipsum dolor sit amet consectetur</b></span>
        <div class="row">

          <div class="col-4">
            <img class="image" src="../../../assets/empty.jpg">

          </div>
          <div class="col-4">
            <img class="image" src="../../../assets/empty.jpg">

          </div>
          <div class="col-4">
            <img class="image" src="../../../assets/empty.jpg">

          </div>
        </div>
      </div>
    </div>

    <div class="row col-two">
      <div class="col-md-6">
        <div class="row col-blue">
          <div class="col-3">
            img
          </div>
          <div class="col-7">
            body
          </div>
          <div class="col-2">
            :
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="row">
          <div class="col-4">
            <img class="image" src="../../../assets/empty.jpg">

          </div>
          <div class="col-4">
            <img class="image" src="../../../assets/empty.jpg">

          </div>
          <div class="col-4">
            <img class="image" src="../../../assets/empty.jpg">

          </div>
        </div>
      </div>
    </div>

    <div class="row col-three">
      <div class="col-md-6">
        <p class="section-title">Servicio de temporalidad</p>
        <p class="c-blue">Lorem ipsum dolor sit amet consectetur adipiscing elit neque, ut nibh luctus dui habitant
          fermentum metus
          turpis himenaeos, vitae dapibus malesuada vehicula bibendum sociosqu posuere. Lacus primis ultrices
          vestibulum ac fringilla per congue, montes curae id augue ut condimentum, diam platea tristique ridiculus
          magna hendrerit. Urna aliquet torquent viverra netus pellentesque </p>
        <div class="row">
          <div class="col-md-8">
            <span class="c-blue" style="font-size: 0.8rem;">
              <b>LOREM IPSUM DOLOR SIT AMET CONSECTETUR</b>
            </span>
          </div>
          <div class="col-md-4 text-right">
            <samp class="c-blue"><b>webpage</b></samp>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <p class="section-title">Servicio de Outsourcing</p>
        <p class="c-blue">Lorem ipsum dolor sit amet consectetur adipiscing elit neque, ut nibh luctus dui habitant
          fermentum metus
          turpis himenaeos, vitae dapibus malesuada vehicula bibendum sociosqu posuere. Lacus primis ultrices
          vestibulum ac fringilla per congue, montes curae id augue ut condimentum, diam platea tristique ridiculus
          magna hendrerit. Urna aliquet torquent viverra netus pellentesque </p>
        <div class="row">
          <div class="col-md-8">
            <span class="c-blue" style="font-size: 0.8rem;">
              <b>LOREM IPSUM DOLOR SIT AMET CONSECTETUR</b>
            </span>
          </div>
          <div class="col-md-4 text-right">
            <samp class="c-blue"><b>webpage</b></samp>
          </div>
        </div>

      </div>

    </div>

    <div class="row col-four">
      <div class="col-md-6">
        <img class="image" src="../../../assets/empty.jpg">
      </div>
      <div class="col-md-6">
        <img class="image" src="../../../assets/empty.jpg">
      </div>
    </div>
    <div class="row col-five">
      <div class="col-md-12">
        <h2>Tutorial</h2>
      </div>

    </div>

    <!--
      <div class="col-md-12" *ngIf="1===2">
      Cargando...
    </div>
 -->


  </div>