<div class="bg-oportunidapp-light-two">
  <div class="container blog-container">
    <!-- Titulo del componente -->
    <div class="col-12 text-center" style="padding-top: 50px;">
      <h4 class="section-title text-center font-weight-bold my-4">
        Nuestro blog
      </h4>
    </div>

    <app-post-card-list></app-post-card-list>
  </div>
</div>
