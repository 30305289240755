<div class="modal-backdrop" style="background-color: #0000007a;"></div>
<div class="modal modal-2 animated fadeInDown" tabindex="-1" role="dialog" style="display: block; overflow-y: auto;">
	<div class="modal-dialog modal-dialog-centered animated fadeInDown" role="document">
		<div class="modal-content">
			<div class="modal-body modal-body--logis">
				<div class="row">
					<div class="col-12 text-center icon-container">
						<img src="assets/Yes.png">
					</div>
				</div>

				<div class="row justify-content-md-center success-message-container">
					<div class="col-md-12 text-center">
						<span class="h6 text-white">
							{{ message }}
						</span>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>