<div class="card " style="width:100%; height: 100%;">
	<img [src]="urlImage" class="card-img-top">
	<!-- <div class="bg-image-responsive" [style.background]="'url(' + urlImage + ')'" style="width: 100%; height: 8em;">
	</div> -->
	<div class="text-center card-body card-body--grey">
		<h6 class="card-title card-title--blue">{{ item.name }}</h6>
		<p class="text-secondary">
			{{ item.description | truncate: 80 }}
		</p>
		<button class="btn btn-ok btn-ok--blue" (click)="goToDetail(item)">Ver más</button>
	</div>
	<!-- <div class="text-center card-body">
		<pre class="card-text card-text--info text-secondary custom-font">{{ item.description | truncate: 100 }}</pre>
	</div> -->
</div>