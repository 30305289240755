<div class="container">
  <page-header class="my-4" header="Blog" [backButton]="false"></page-header>

  <only-search-filter
    searchKey="postSearchQuery"
    (onSearch)="searchFilter($event)"
  ></only-search-filter>

  <ng-container *ngIf="blogs$ | async; let blogs; else: loading">
    <ng-container *ngIf="blogs?.length > 0; else noData">
      <!-- ................................................................................................. -->
      <!-- TABLE -->
      <!-- ................................................................................................. -->
      <div class="row">
        <div class="col-12">
          <div class="table-responsive-sm">
            <table class="table card-table">
              <thead>
                <tr>
                  <th *ngFor="let head of tableHeaders" scope="col">
                    {{ head }}
                  </th>
                  <!-- actions -->
                  <th scope="col" style="width: 150px;"></th>
                </tr>
              </thead>
              <tbody>
                <!-- Separator for showing a space between thead an tbody -->
                <tr class="table-separator">
                  <td [colSpan]="this.tableHeaders.length + 1000"></td>
                </tr>

                <ng-container *ngFor="let post of blogs">
                  <tr class="pointer">
                    <td class="font-weight-light">
                      <img
                        [src]="utils.getImgSrc(post.images[0])"
                        style="width: 50px;height: 50px;"
                        class="img-fluid img-rounded"
                        alt=""
                      />
                    </td>
                    <td class="font-weight-light">
                      {{ post.name }}
                    </td>
                    <td class="font-weight-light">
                      {{ post.author }}
                    </td>
                    <td class="font-weight-light">
                      {{ post.description | slice:0:25 }}
                    </td>
                    <td class="font-weight-light">
                      {{ post.aboutAuthor | slice:0:25 }}
                    </td>
                    <td class="font-weight-light">
                      <img
                        class="action-icon"
                        (click)="openFormModal(post)"
                        [src]="utils.assets('actions/edit.png')"
                      />
                      <img
                        class="action-icon"
                        (click)="confirmDelete(post)"
                        [src]="utils.assets('actions/delete.png')"
                      />
                    </td>
                  </tr>
                </ng-container>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <!-- ................................................................................................. -->
      <!-- PAGINATION -->
      <!-- ................................................................................................. -->
      <div class="row mt-4">
        <div class="col-md-12">
          <app-pagination
            [pagination]="pagination"
            [loader]="loadingPage"
            (_changePage)="goToPage($event)"
          >
          </app-pagination>
        </div>
      </div>
    </ng-container>
  </ng-container>

  <!-- ................................................................................................. -->
  <!-- LOADING TEMPLATE -->
  <!-- ................................................................................................. -->
  <ng-template #loading>
    <div class="row">
      <div class="col text-center">
        <i class="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i>
      </div>
    </div>
  </ng-template>

  <!-- ................................................................................................. -->
  <!-- NO DATA -->
  <!-- ................................................................................................. -->
  <ng-template #noData>
    <div class="row mt-4">
      <div class="col-12 text-center">
        <h4 class="h4 text-secondary">
          No se han encontrado resultados.
        </h4>
      </div>
    </div>
  </ng-template>

  <!-- ................................................................................................. -->
  <!-- CREATE -->
  <!-- ................................................................................................. -->
  <floating-button
    [icon]="utils.assets('actions/addplus.png')"
    (onClick)="openFormModal()"
  ></floating-button>
</div>
