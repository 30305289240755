<div class="modal-backdrop" style="background-color: #0000007a;"></div>
<div class="modal modal-2 animated fadeInDown" tabindex="-1" role="dialog" style="display: block; overflow-y: auto;z-index: 1500 !important;">
	<div class="modal-dialog modal-login modal-dialog-centered" role="document">
		<div class="modal-content modal-login">
			<div class="d-flex justify-content-center">
				<button class="btn btn-rounded btn-rounded-close" (click)="close()">
					<i class="fa fa-times" aria-hidden="true"></i>
				</button>
			</div>
			<div class="modal-body">
				<form [formGroup]="formLogin">
					<div class="row">
						<div class="col-12 text-center">
							<img src="assets/banner.png" style="width: 12rem;">
						</div>
					</div>
					<div class="row" style="margin-top: 10px;">
						<div class="col-12 text-center" style="font-size: 0.8rem;">
							<span class="text-secondary">Su sessión ha caducado. Por favor Ingrese su contraseña para
								continuar.</span>
						</div>
					</div>

					<br>
					<div class="row">
						<div class="col-12 form-label-group form-group text-center">
							<div class="img-rounded" [style.background]="'url(' + getUrlImage(currentUser?.image) + ')'" style="width: 3.5rem; height: 3.5rem; margin: auto;"></div>
							<span class="text-secondary">
								{{ formLogin.controls.email.value }}
							</span>
						</div>
					</div>

					<div class="row">
						<div class="col-12 form-label-group">
							<input type="password" id="inputPassword" formControlName="password"
								class="form-control form-control-lg" (focus)="clearMessage()" placeholder="Contraseña">
							<label for="inputPassword">Contraseña</label>
							<field-error [fcontrol]="formLogin.get('password')"></field-error>
						</div>
					</div>

					<div class="row">
						<!-- 
						<div class="col form-group">
							<div class="custom-control custom-checkbox">
								<input type="checkbox" class="custom-control-input" id="customCheck1">
								<label class="custom-control-label" for="customCheck1">Recuérdame</label> 
							</div>
						</div>-->
						<div class="col-auto form-group">
							<a routerLink="./">
								¿Olvidaste tu contraseña?
							</a>
						</div>
					</div>

					<div class="row">
						<div class="col-12 form-group">
							<span class="text-danger">
								{{ errorMessage }}
							</span>
						</div>
					</div>

					<div class="row justify-content-center">
						<div class="col-6">
							<button type="submit" [disabled]="_loading" class="btn btn-block btn-ok btn-ok--blue"
								(click)="logIn()">
								<i *ngIf="_loading" class="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i>
								Iniciar sesión
							</button>
						</div>
					</div>
				</form>
			</div>
			<div class="modal-body bg-light" style="padding-top: 5px;">
				<!-- <div class="row">
					<div class="col-12 text-center">
						<h3 class="text-secondary font-weight-bolder">O ingresa con</h3>
					</div>
				</div>

				<div class="row justify-content-center">
					<div class="col text-right form-group">
						<img src="assets/facebook.png" style="width: 4rem;">
					</div>
					<div class="col text-left form-group">
						<img src="assets/google-plus.png" style="width: 4rem;">
					</div>
				</div>

				<div class="row justify-content-center">
					<div class="col-auto form-group" style="font-size: 0.9rem;">
						<span class="text-secondary">¿No tienes cuenta?</span>
					</div>
				</div>

				<div class="row justify-content-center">
					<div class="col-auto">
						<button type="button" class="btn btn-outline-secondary">Regístrate</button>
					</div>
				</div> -->

				<div class="row justify-content-center">
					<div class="col-auto form-group" style="font-size: 0.9rem;">
						<a href="{{docsApp.usagePolicies}}" target="_BLANCK">Política de uso</a> -
						<a href="{{docsApp.terms}}" target="_BLANCK">Términos y condiciones</a>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>