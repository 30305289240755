<div class="modal-backdrop" style="background-color: #0000007a;"></div>
<div class="modal modal-2 animated fadeInDown" tabindex="-1" role="dialog" style="display: block; overflow-y: auto;">
  <div class="modal-dialog modal-dialog-centered modal-md" role="document">
    <div class="modal-content">
      <div class="d-flex justify-content-center">
        <button class="btn btn-rounded btn-rounded-close" (click)="close()">
          <i class="fa fa-times" aria-hidden="true"></i>
        </button>
      </div>
      <div class="modal-body modal-body--logis">
        <h5 class="m-4 text-center">{{ _title }} información de referencia personal/familiar</h5>
        <hr class="mt-3 mb-4">

        <div class="row" *ngIf="_loadingForm">
          <div class="col-md-12">
            Cargando...
          </div>
        </div>
        <div class="row" *ngIf="!_loadingForm && !_Entity">
          <div class="col-md-12">
            Error cargando formulario
          </div>
        </div>

        <form [formGroup]="FormEntity" *ngIf="!_loadingForm && _Entity">

          <div class="row">
            <div class="col-md-6 col-xs-12 col-sm-12 form-group">
              <label for="">Nombre completo</label>
              <input type="text" class="form-control input-2" formControlName="name" maxlength="100">
              <span class="text-danger invalid-control"
                *ngIf="FormEntity.controls.name.invalid && FormEntity.controls.name.dirty">
                Campo requerido
              </span>
            </div>
            <div class="col-md-6 col-xs-12 col-sm-12 form-group">
              <label for="">Ocupación</label>
              <input type="text" class="form-control input-2" formControlName="occupation" maxlength="100">
              <span class="text-danger invalid-control"
                *ngIf="FormEntity.controls.occupation.invalid && FormEntity.controls.occupation.dirty">
                Campo requerido
              </span>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6 col-xs-12 col-sm-12 form-group">
              <label for="">Parentesco</label>
              <!-- <input type="text" class="form-control input-2" formControlName="relationship" maxlength="100"> -->
               <select class="form-control input-2" name="miselect" [(ngModel)]="seleccionado" formControlName="relationship">
                 <option [value]="item" *ngFor="let item of relationshipList">{{item}}</option>
               </select>
              <span class="text-danger invalid-control"
                *ngIf="FormEntity.controls.relationship.invalid && FormEntity.controls.relationship.dirty">
                Campo requerido
              </span>
            </div>
            <div class="col-md-6 col-xs-12 col-sm-12 form-group">
              <label for="">Dirección</label>
              <input type="text" class="form-control input-2" formControlName="address" maxlength="100">
              <span class="text-danger invalid-control"
                *ngIf="FormEntity.controls.address.invalid && FormEntity.controls.address.dirty">
                Campo requerido
              </span>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6 col-xs-12 col-sm-12 form-group">
              <label for="">Teléfono</label>
              <input type="text" class="form-control input-2" formControlName="cellphone" [patterns]="_maskPhone"
                mask='ABBBBBBBBBBBBBB' [validation]="false" [dropSpecialCharacters]="false" maxlength="15">
              <span class="text-danger invalid-control"
                *ngIf="FormEntity.controls.cellphone.invalid && FormEntity.controls.cellphone.dirty">
                Campo requerido
              </span>
            </div>
            <div class="col-md-6 col-xs-12 col-sm-12 form-group"></div>
          </div>


          <div class="row justify-content-center m-4">
            <div class="col-4 text-center">
              <button type="submit" [disabled]="_loading" class="btn btn-block btn-ok btn-ok--blue" (click)="save()">
                <i *ngIf="_loading" class="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i>
                {{ _btnText }}
              </button>
            </div>
          </div>

        </form>

      </div>
    </div>
  </div>
</div>