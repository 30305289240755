<ng-container *ngIf="posts$ | async; let posts; else: loading">
  <ng-container *ngIf="posts?.length > 0; else noData">
    <div class="row">
      <div class="col-12 justify-content-center our-blog-page">
        <ng-container *ngFor="let item of posts">
          <app-card-blog
            [blog]="item"
            (viewDetail)="goToPostDetail($event)"
          ></app-card-blog>
        </ng-container>
      </div>
    </div>
    <div class="col-12" *ngIf="showPagination">
      <app-pagination
        [pagination]="pagination"
        [loader]="loadingPage"
        (_changePage)="goToPage($event)"
      ></app-pagination>
    </div>
  </ng-container>
</ng-container>

<!-- ................................................................................................. -->
<!-- LOADING TEMPLATE -->
<!-- ................................................................................................. -->
<ng-template #loading>
  <div class="row">
    <div class="col text-center">
      <i class="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i>
    </div>
  </div>
</ng-template>

<!-- ................................................................................................. -->
<!-- NO DATA -->
<!-- ................................................................................................. -->
<ng-template #noData>
  <div class="row mt-4">
    <div class="col-12 text-center">
      <h4 class="h4 text-secondary">
        No se han encontrado resultados.
      </h4>
    </div>
  </div>
</ng-template>
