<div class="bg-oportunidapp">
  <div class="bg-left">
    <img [src]="utils.assets('banner.png')" alt="">
  </div>
  <div class="container" *ngIf="(psychoTest$ | async); let psychoTest">

    <!-- ................................................................................................. -->
    <!-- HEADER AND USER INFO -->
    <!-- ................................................................................................. -->
    <div class="row justify-content-center my-5">
      <div class="col-8">
        <h2 class="font-weight-bold text-center">{{title}}</h2>
      </div>
    </div>

    <div class="row my-5">
      <div class="col-12 col-md">
        <label class="font-weight-bold">Nombre:</label>
        <span class="ml-1">{{psychoTest.user.fullName}}
        </span>
      </div>
      <div class="col-12 col-md">
        <label class="font-weight-bold">Edad:</label>
        <span class="ml-1">{{psychoTest.user?.age || 'No aplica.'}}
        </span>
      </div>
      <div class="col-12 col-md">
        <label class="font-weight-bold">Cédula:</label>
        <span class="ml-1">{{psychoTest.user?.identification || 'No aplica.'}}
        </span>
      </div>
      <div class="col-12 col-md">
        <label class="font-weight-bold">Fecha de aplicación:</label>
        <span class="ml-1">{{psychoTest.test.createdAt | date: 'dd/MM/yy'}}
        </span>
      </div>
    </div>

    <!-- ................................................................................................. -->
    <!-- TEST NAME -->
    <!-- ................................................................................................. -->
    <div class="row justify-content-center my-5">
      <div class="col-8">
        <h2 class="font-weight-bold text-center">Resultado de evaluación de competencias</h2>
      </div>
    </div>

    <div class="row justify-content-center my-5">
      <div class="col-8">
        <h2 class="font-weight-bold text-center">{{psychoTest.test.name}}</h2>
      </div>
    </div>

    <!-- ................................................................................................. -->
    <!-- TABLE -->
    <!-- ................................................................................................. -->
    <div class="row my-5">
      <div class="col-12">
        <table class="table-print table-border-on-right">
          <thead>
            <tr>
              <th style="background-color: #00528c;" [ngClass]="{'border-lr-only': i == 0}"
                class="text-white font-weight-bold" *ngFor="let h of headers; let i = index">
                {{h}}
              </th>
            </tr>
          </thead>
  
          <tbody>
            <ng-container *ngFor="let item of psychoTest.test.data;let i = index">
              <tr [ngClass]=" 0 === (i+1)%2? 'bg-cell-info': ''">
                <td class="border-lr-only text-white"
                  [ngClass]=" 0 === (i+1)%2? 'bg-cell-info-darken': 'bg-cell-info-soft'">
                  {{item.behavior.name}}
                </td>
                <td class="font-weight-bold">
                  {{item.behavior.feedback}}
                </td>
                <td class="font-weight-bold">
                  {{item.behavior.percentage}}
                </td>
                <td class="font-weight-bold">
                  {{item.behavior.percentageText}}
                </td>
                <td class="font-weight-bold">
                  {{item.behavior.trainingPlan}}
                </td>
              </tr>
            </ng-container>
  
          </tbody>
        </table>
      </div>
    </div>

    <!-- ................................................................................................. -->
    <!-- CONCEPT -->
    <!-- ................................................................................................. -->
    <div class="row justify-content-center my-5">
      <div class="col-8">
        <h2 class="font-weight-bold text-center">Concepto</h2>
      </div>
    </div>

    <div class="row justify-content-center my-5">
      <div class="col-12">
        <p class="text-justify">{{feedback}}</p>
      </div>
    </div>

    <div class="row justify-content-center">
      <div class="col-auto">
        <a class="text-alignt right text-primary pointer" (click)="print()">Imprimir este registro</a>
      </div>
    </div>
  </div>
</div>