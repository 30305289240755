<ng-container
  *ngIf="qualifications$ | async; let qualifications; else: loading"
>
  <ng-container *ngIf="qualifications?.length > 0; else noData">
    <div class="row">
      <div class="col-12">
        <div class="d-flex justify-content-between">
          <p class="title-page mt-4">
            Calificación de
            {{
              typeQualify === qualify.CANDIDATES
                ? "los candidatos"
                : "la plataforma"
            }}
          </p>
          <p class="title-page results mt-4">
            {{ this.pagination?.elementsNumber }}
            {{
              this.pagination?.elementsNumber > 1 ? "Resultados" : "Resultado"
            }}
          </p>
        </div>
      </div>
      <div class="col-12 mt-3">
        <form [formGroup]="formGroup">
          <div class="row">
            <div class="col-md-9 col-sm-9 col-xs-12">
              <div class="row">
                <div class="col-md-3 col-sm-3 col-xs-12 mb-1">
                  <custom-select
                    [items]="companies$ | async"
                    [typeahead]="companiesSearch$"
                    [loading]="loadingCompanies"
                    [formGroup]="formGroup"
                    [controlName]="'companyId'"
                    [placeholder]="'Empresa'"
                    [bindLabel]="'name'"
                  ></custom-select>
                </div>
                <ng-container *ngIf="typeQualify === qualify.CANDIDATES">
                  <div class="col-md-3 col-sm-3 col-xs-12 mb-1">
                    <custom-select
                      [items]="vacancies$ | async"
                      [typeahead]="vacancySearch$"
                      [loading]="loadingVacancies"
                      [formGroup]="formGroup"
                      [controlName]="'vacancyId'"
                      [placeholder]="'Vacante'"
                      [bindLabel]="'name'"
                    ></custom-select>
                  </div>
                  <div class="col-md-3 col-sm-3 col-xs-12 mb-1">
                    <custom-select
                      [items]="users$ | async"
                      [typeahead]="userSearch$"
                      [loading]="loadingUsers"
                      [formGroup]="formGroup"
                      [controlName]="'userId'"
                      [placeholder]="'Agente'"
                      [bindLabel]="'fullName'"
                      [bindValue]="'id'"
                    ></custom-select>
                  </div>
                </ng-container>
                <div class="col-md-3 col-sm-3 col-xs-12 mb-1">
                  <custom-select
                    [items]="qualified"
                    [formGroup]="formGroup"
                    [controlName]="'qualifyId'"
                    [placeholder]="'Calificaciones'"
                    [searchable]="false"
                  ></custom-select>
                </div>
              </div>
            </div>
            <div class="col-md-3 col-sm-3 col-xs-12 text-right">
              <button
                [disabled]="loadingQualifications"
                type="button"
                class="p-2 btn btn-outline-success"
                (click)="exportExcel()"
              >
                <i
                  *ngIf="loadingQualifications"
                  class="fa fa-circle-o-notch fa-spin"
                  aria-hidden="true"
                ></i>
                Exportar calificaciones
              </button>
            </div>
          </div>
        </form>
      </div>
      <div class="col-12 mt-2">
        <div class="row">
          <div class="col-12">
            <div class="table-responsive-sm">
              <table class="table simple-table">
                <thead>
                  <tr>
                    <th *ngFor="let head of tableHeaders" scope="col">
                      {{ head }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <!-- rows for data -->
                  <tr
                    class="show-questions"
                    *ngFor="let qualification of qualifications"
                  >
                    <td class="font-weight-light">
                      {{ qualification.companyName }}
                    </td>
                    <ng-container *ngIf="typeQualify === qualify.CANDIDATES">
                      <td class="font-weight-light">
                        {{ qualification.agent }}
                      </td>
                      <td class="font-weight-light">
                        {{ qualification.vacancy }}
                      </td>
                    </ng-container>
                    <td class="font-weight-light">
                      <ngb-rating
                        [rate]="qualification.qualification"
                        max="5"
                        [readonly]="true"
                      >
                        <ng-template let-fill="fill" let-index="index">
                          <span
                            class="star"
                            [class.filled]="fill === 100"
                            [class.bad]="index < 5"
                            >&#9733;</span
                          >
                        </ng-template>
                      </ngb-rating>
                    </td>
                    <td class="font-weight-light">
                      {{ qualification.comment }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12">
        <!-- ................................................................................................. -->
        <!-- PAGINATION -->
        <!-- ................................................................................................. -->
        <div class="row mt-4">
          <div class="col-md-12">
            <app-pagination
              [pagination]="pagination"
              [loader]="loadingPage"
              (_changePage)="goToPage($event)"
            >
            </app-pagination>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>
<!-- ................................................................................................. -->
<!-- LOADING TEMPLATE -->
<!-- ................................................................................................. -->
<ng-template #loading>
  <div class="row">
    <div class="col text-center">
      <i class="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i>
    </div>
  </div>
</ng-template>

<!-- ................................................................................................. -->
<!-- NO DATA -->
<!-- ................................................................................................. -->
<ng-template #noData>
  <div class="row mt-4">
    <div class="col-12 text-center">
      <i class="fa fa-inbox noResults" aria-hidden="true"></i> <br />
      <h4 class="h4 text-secondary">
        No se han encontrado resultados.
      </h4>
    </div>
  </div>
</ng-template>
