<div class="modal-backdrop" style="background-color: #0000007a;"></div>

<div class="modal modal-2 animated fadeInDown" tabindex="-1" role="dialog" style="display: block; overflow-y: auto;">
	<div class="modal-dialog modal-dialog-centered modal-md" role="document">
		<div class="modal-content">
			<div class="d-flex justify-content-center">
				<a class="btn btn-rounded btn-rounded-close" style="cursor: pointer; color: white;"
					(click)="closeModal()">
					<i class="fa fa-times" aria-hidden="true"></i>
				</a>
			</div>

			<div class="modal-body modal-body--logis">

				<div class="col-md-12">

					<div class="row mb-4 mt-2">
						<div class="col-md-12" style="text-align: center">
							<h2>Cambiar contraseña</h2>
						</div>
					</div>

					<div class="row" *ngIf="_loadingPage">
						<div class="col-md-12">
							Cargando...
						</div>
					</div>

					<div class="row" *ngIf="!_loadingPage && !_validToken">
						<div class="col-md-12 text-center">
							<h5 class="text-secondary">
								El enlace de verificación es inválido o ha caducado
							</h5>
						</div>
					</div>

					<form [formGroup]="formRestore" *ngIf="!_loadingPage && _validToken">
						<div class="row justify-content-md-center">
							<div class="col-md-8 form-group" [ngbPopover]="popContent" #pPassword="ngbPopover"
								[autoClose]="false" triggers="manual">
								<input type="password" formControlName="password" (focus)="pPassword.open()"
									(focusout)="pPassword.close()" class="form-control input-1" required />
								<label class="input-1__label">Contraseña nueva</label>
							</div>
						</div>

						<div class="row justify-content-md-center">
							<div class="col-md-8 form-group">
								<input type="password" formControlName="passwordConfirmation"
									class="form-control input-1" required />
								<label class="input-1__label">Confirmar contraseña</label>

								<span class="text-danger"
									*ngIf="formRestore.controls.passwordConfirmation.dirty && formRestore.controls.password.value !== formRestore.controls.passwordConfirmation.value">
									<small>La contraseña no coincide</small>
								</span>
							</div>
						</div>
					</form>


					<br />

					<div class="row justify-content-md-center">
						<div class="col-md-3">
							<button *ngIf="!_loadingPage && _validToken" type="button"
								class="btn btn-yellow btn-block btn-lg btn-ok btn-ok--blue"
								[disabled]="formRestore.invalid || _loading || (formRestore.controls.password.value !== formRestore.controls.passwordConfirmation.value)"
								(click)="resetPassword()">
								<i *ngIf="_loading" class="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i>
								Enviar
							</button>
						</div>
					</div>
					<br>

					<div class="row">
						<div class="col-md-12 text-center">
							<ng-container *ngIf="_businessProfile">
								<a routerLink="../../login">
									Iniciar sesión
								</a>
							</ng-container>
							<ng-container *ngIf="!_businessProfile">
								<a routerLink="../../">
									Iniciar sesión
								</a>
							</ng-container>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<ng-template #popContent let-greeting="greeting">
	<div class="col-md-12">
		<h6>Requerimientos</h6>
		<ul>
			<li>
				<span [ngClass]="validatePWrequirement_1 ? 'pwValid' : 'pwInvalid'">
					<i *ngIf="validatePWrequirement_1" class="fa fa-check-circle-o" aria-hidden="true"></i>
					<i *ngIf="!validatePWrequirement_1" class="fa fa-times-circle-o" aria-hidden="true"></i>
					Al menos un número
				</span>
			</li>
			<li>
				<span [ngClass]="validatePWrequirement_2 ? 'pwValid' : 'pwInvalid'">
					<i *ngIf="validatePWrequirement_2" class="fa fa-check-circle-o" aria-hidden="true"></i>
					<i *ngIf="!validatePWrequirement_2" class="fa fa-times-circle-o" aria-hidden="true"></i>
					Al menos una letra
				</span>
			</li>
			<li>
				<span [ngClass]="validatePWrequirement_3 ? 'pwValid' : 'pwInvalid'">
					<i *ngIf="validatePWrequirement_3" class="fa fa-check-circle-o" aria-hidden="true"></i>
					<i *ngIf="!validatePWrequirement_3" class="fa fa-times-circle-o" aria-hidden="true"></i>
					Sin espacios en blanco
				</span>
			</li>
			<li>
				<span [ngClass]="validatePWrequirement_4 ? 'pwValid' : 'pwInvalid'">
					<i *ngIf="validatePWrequirement_4" class="fa fa-check-circle-o" aria-hidden="true"></i>
					<i *ngIf="!validatePWrequirement_4" class="fa fa-times-circle-o" aria-hidden="true"></i>
					Mínimo 6 caracteres
				</span>
			</li>
		</ul>
	</div>
</ng-template>