<h3 class="mt-2 mb-2 text-center">Cambiar contraseña</h3>
<form [formGroup]="formPassword">
  <div class="row mt-4">
    <div class="col-md-12 col-xs-12 col-sm-12 form-group">
      <label>Contraseña actual*</label>
      <input
        type="password"
        class="form-control input-2"
        formControlName="currentPassword"
        autocomplete="on"
      />
      <field-error
        [fcontrol]="formPassword.get('currentPassword')"
      ></field-error>
    </div>
    <div
      class="col-md-12 col-xs-12 col-sm-12 form-group"
      [ngbPopover]="popContent"
      #pPassword="ngbPopover"
      placement="left"
      [autoClose]="false"
      triggers="manual"
    >
      <label>Nueva contraseña*</label>
      <input
        type="password"
        class="form-control input-2"
        formControlName="newPassword"
        (focus)="pPassword.open()"
        (focusout)="pPassword.close()"
        autocomplete="on"
      />
      <field-error [fcontrol]="formPassword.get('newPassword')"></field-error>
    </div>
    <div class="col-md-12 col-xs-12 col-sm-12 form-group">
      <label>Confirmar nueva contraseña*</label>
      <input
        type="password"
        class="form-control input-2"
        formControlName="repeatNewPassword"
        autocomplete="on"
      />
      <field-error
        [fcontrol]="formPassword.get('repeatNewPassword')"
      ></field-error>
      <span
        class="small text-danger"
        *ngIf="
          formPassword.hasError('notSame') &&
          formPassword.get('repeatNewPassword').dirty
        "
      >
        Las contraseñas no coinciden
      </span>
    </div>
  </div>
  <div class="row mt-3 mb-3 d-flex justify-content-center">
    <button
      [disabled]="(loading$ | async) || formPassword.invalid"
      (click)="onClick()"
      class="btn btn-ok--blue"
    >
      <ng-container *ngIf="!(loading$ | async); else loading">
        Guardar
      </ng-container>
      <ng-template #loading>
        Guardando
        <i
          class="fa fa-circle-o-notch fa-spin text-white"
          aria-hidden="true"
        ></i>
      </ng-template>
    </button>
  </div>
</form>

<ng-template #popContent let-greeting="greeting">
  <app-password-requirements
    [password]="formPassword.get('newPassword').value"
  ></app-password-requirements>
</ng-template>
