<div class="modal-backdrop" style="background-color: #0000007a;"></div>

<div class="modal modal-2 animated fadeInDown" tabindex="-1" role="dialog" style="display: block; overflow-y: auto;">
	<div class="modal-dialog modal-dialog-centered modal-md" role="document">
		<div class="modal-content">
			<div class="d-flex justify-content-center">
				<a class="btn btn-rounded btn-rounded-close" routerLink="../">
					<i class="fa fa-times" aria-hidden="true"></i>
				</a>
			</div>
			<div class="modal-body modal-body--logis">
				<div class="col-md-12">

					<div class="row" style="margin-top: 10px; margin-bottom: 15px;">
						<div class="col-md-12" style="text-align: center">
							<h3>Recuperar contraseña</h3>
						</div>
					</div>

					<div class="row">
						<div class="col-md-12 text-center">
							<h5 class="text-dark" style="font-size: 0.95rem;">
								Te enviaremos un enlace para que puedas restablecer tu contraseña
							</h5>
						</div>
					</div>

					<br>

					<div class="row justify-content-md-center">
						<div class="col-md-8 form-group">
							<input type="text" [formControl]="_emailControl" class="form-control input-1" required />
							<label class="input-1__label">Correo electrónico</label>
						</div>
					</div>

					<div class="row justify-content-md-center" style="margin-bottom: 10px;">
						<div class="col-md-3">
							<button type="button" class="btn btn-block btn-ok btn-ok--blue" [disabled]="_emailControl.invalid || _loading" (click)="recovery()">
								<i *ngIf="_loading" class="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i>
								Enviar
							</button>
						</div>
					</div>

					<div class="row">
						<div class="col-md-12 text-center">
							<a routerLink="../">
								Iniciar sesión
							</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>