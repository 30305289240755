<div class="row">
	<div class="col-md-12">
		<h6>Requerimientos</h6>
		<p>
			<span class="text-secondary">
				<!-- Caracteres especiales permitidos [, . - _] -->
				Caracteres especiales permitidos (punto, guión bajo y guión medio)
			</span>
		</p>
		<ul style="list-style: none; padding-left: 0px;">
			<li>
				<span [ngClass]="validatePWrequirement_5 ? 'pwValid' : 'pwInvalid'">
					<i *ngIf="validatePWrequirement_5" class="fa fa-check-circle-o" aria-hidden="true"></i>
					<i *ngIf="!validatePWrequirement_5" class="fa fa-times-circle-o" aria-hidden="true"></i>
					&nbsp; No incluir mayúsculas
				</span>
			</li>
			<li>
				<span [ngClass]="validatePWrequirement_1 ? 'pwValid' : 'pwInvalid'">
					<i *ngIf="validatePWrequirement_1" class="fa fa-check-circle-o" aria-hidden="true"></i>
					<i *ngIf="!validatePWrequirement_1" class="fa fa-times-circle-o" aria-hidden="true"></i>
					&nbsp; No iniciar con caracteres especiales
				</span>
			</li>
			<li>
				<span [ngClass]="validatePWrequirement_2 ? 'pwValid' : 'pwInvalid'">
					<i *ngIf="validatePWrequirement_2" class="fa fa-check-circle-o" aria-hidden="true"></i>
					<i *ngIf="!validatePWrequirement_2" class="fa fa-times-circle-o" aria-hidden="true"></i>
					&nbsp; No finalizar con caracteres especiales
				</span>
			</li>
			<li>
				<span [ngClass]="validatePWrequirement_3 ? 'pwValid' : 'pwInvalid'">
					<i *ngIf="validatePWrequirement_3" class="fa fa-check-circle-o" aria-hidden="true"></i>
					<i *ngIf="!validatePWrequirement_3" class="fa fa-times-circle-o" aria-hidden="true"></i>
					&nbsp; Sin espacios en blanco
				</span>
			</li>
			<li>
				<span [ngClass]="validatePWrequirement_4 ? 'pwValid' : 'pwInvalid'">
					<i *ngIf="validatePWrequirement_4" class="fa fa-check-circle-o" aria-hidden="true"></i>
					<i *ngIf="!validatePWrequirement_4" class="fa fa-times-circle-o" aria-hidden="true"></i>
					&nbsp; Mínimo 6 caracteres
				</span>
			</li>
		</ul>
	</div>
</div>