<div class="modal-backdrop" style="background-color: #0000007a; z-index: 1050;"></div>
<div class="modal modal-2 animated fadeInDown" tabindex="-1" role="dialog" style="display: block; overflow-y: auto;"
  (click)="closeBackDrop && closeBackDrop()">
  <div class="modal-dialog modal-dialog-centered animated fadeInDown" role="document">
    <div class="modal-content">
      <!----------------------------------------->
      <!-- MODAL CLOSE BUTTON-->
      <!----------------------------------------->
      <div class="d-flex justify-content-center" *ngIf="closeButton">
        <button class="btn btn-rounded btn-rounded-close" (click)="closeButton()" [disabled]="disabled">
          <i class="fa fa-times" aria-hidden="true"></i>
        </button>
      </div>
      <!----------------------------------------->
      <!-- MODAL TOP -->
      <!----------------------------------------->
      <div class="modal-body modal-body--logis pt-4" [ngClass]="bgTop && bgColor">
        <div class="row m-3">
          <!----------------------------------------->
          <!-- ICON -->
          <!----------------------------------------->
          <div class="col-12 text-center " *ngIf="icon" [ngClass]="closeButton && 'mt-3'">
            <img src="{{icon}}">
          </div>
          <!----------------------------------------->
          <!-- TITLE -->
          <!----------------------------------------->
          <div class="col-12 text-center mt-4 " *ngIf="title">
            <h6 [ngClass]="bgTop && 'text-white'">{{title}}</h6>
          </div>
        </div>
      </div>
      <!----------------------------------------->
      <!-- MODAL BOTTOM -->
      <!----------------------------------------->
      <div class="modal-body pt-3" [ngClass]="bgBottom && bgColor">
        <!----------------------------------------->
        <!-- TITLE MESSAGE -->
        <!----------------------------------------->
        <div class="row justify-content-center mt-3" *ngIf="titleMessage">
          <p><b>{{titleMessage}}</b></p>
        </div>
        <!----------------------------------------->
        <!-- MESSAGE -->
        <!----------------------------------------->
        <div class="row justify-content-center mb-3" [ngClass]="!titleMessage && 'mt-3'" *ngIf="message">
          <div class="col-md-12 text-center" [ngClass]="message">
            <span class="h6 " [ngClass]="bgBottom && 'text-white'">
              {{ message }}
            </span>
          </div>
        </div>
        <!----------------------------------------->
        <!-- BUTTONS-->
        <!----------------------------------------->
        <ng-container *ngIf="buttons.length">
          <div class="row mt-4  mb-3 d-flex " [ngClass]="buttons.length <= 2 && 'justify-content-center'">
            <button *ngFor="let button of buttons" type="button" class="m-2 "
              [ngClass]="button.class ? button.class : 'bg-button-default' " [ngStyle]="{'opacity': disabled ? .65 : 1}"
              [disabled]="disabled" (click)="button.onClick()">
              <i *ngIf="button.loading" class="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i>
              {{button.name}}
            </button>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>