<div class="modal-backdrop" style="background-color: #0000007a; z-index: 1050;"></div>
<div class="modal modal-2 animated fadeInDown" tabindex="-1" role="dialog" style="display: block; overflow-y: auto;">
  <div class="modal-dialog modal-dialog-centered modal-md animated fadeInDown" role="document">
    <div class="modal-content">
      <div class="d-flex justify-content-center">
        <button class="btn btn-rounded btn-rounded-close" (click)="onCloseModalQuestion()">
          <i class="fa fa-times" aria-hidden="true"></i>
        </button>
      </div>
      <div class="modal-body modal-body--logis ">
        <h2>Pre-Entrevista Pendiente</h2>
        <span>Información solicitada</span>
        <hr>
        <div class="row pb-4">
          <div class="col-md-12 text-left">
            <angular-editor id="no-border" [(ngModel)]="question" [config]="disabledEditor">
            </angular-editor>
          </div>
          <!-- <div class="col-md-6 pt-3">
            <app-youtube-video [videoId]="ytIdPreinterviews"></app-youtube-video>
          </div> -->
        </div>
        <hr class="pb-4">
        <div class="row">
          <div class="col-12 text-center">
            <button class="btn btn-ok--dark-blue input-2 btn-add-interview" (click)="onUploadVideoPreInterview()">
              Adjuntar entrevista <img class="ml-2" src="assets/actions/upload.png">
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>