import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-card-with-footer',
  templateUrl: './card-with-footer.component.html',
  styleUrls: ['./card-with-footer.component.scss']
})
export class CardWithFooterComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
