<!-- Titulo del componente -->
<div class="row">
	<div class="col-12 text-center">
		<h4 class="section-title text-center font-weight-bold my-4">Nuestros aliados</h4>
	</div>
</div>

<ng-container *ngIf="!_loadingInit; else LoadingTemplate">
	<ng-container *ngIf="_error == ''; else errorTemplate">
		<ng-container *ngIf="_alliances?.length; else noDataTemplate">
			<div class="row justify-content-center">
				<div class="col-md-10">
					<div class="row card-deck">
						<ng-container *ngFor="let item of _alliances">
							<div class="col-md-4 col-sm-6" style="margin-top: 25px;">
								<app-card-alliance [alliance]="item"></app-card-alliance>
							</div>
						</ng-container>
					</div>
				</div>
			</div>

			<br><br>
			<div class="row">
				<div class="col-12 text-center">
					<button class="btn btn btn-outline-secondary" routerLink="/home/partners">
						Ver todos
					</button>
				</div>
			</div>
		</ng-container>
	</ng-container>
</ng-container>

<ng-template #LoadingTemplate>
	<div class="row">
		<div class="col">
			Cargando ...
		</div>
	</div>
</ng-template>

<ng-template #errorTemplate>
	<div class="row">
		<div class="col text-center text-secondary">
			<i class="fa fa-inbox fa-4x" aria-hidden="true"></i>
			<h4 class="h4">
				Error cargando información de aliados
			</h4>
			<p class="text-center">
				{{ _error }}
			</p>
		</div>
	</div>
</ng-template>

<ng-template #noDataTemplate>
	<div class="row">
		<div class="col text-center text-secondary">
			<i class="fa fa-inbox fa-4x" aria-hidden="true"></i>
			<h4 class="h4">
				Aún no hay registros de aliados
			</h4>
		</div>
	</div>
</ng-template>