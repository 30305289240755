<div class="card">
	<div class="card-body">
		<ng-container *ngIf="_loadingInit">
			<div class="row">
				<div class="col-12">
					Cargando...
				</div>
			</div>
		</ng-container>

		<ng-container *ngIf="!_loadingInit && !_result?.data.length">
			<div class="row">
				<div class="col text-center">
					<i class="fa fa-inbox noResults" aria-hidden="true"></i>

					<ng-container *ngIf="_error == ''">
						<h4 class="h4 text-secondary">
							Aún no has aplicado a vacantes.
						</h4>
					</ng-container>

					<ng-container *ngIf="_error != ''">
						<h4 class="h4 text-secondary">
							Error cargando información de vacantes.
						</h4>
						<p class="text-center text-secondary">
							{{ _error }}
						</p>
					</ng-container>
				</div>
			</div>
		</ng-container>

		<div class="col" *ngIf="!_loadingInit && _result?.data.length">
			<div class="row">
				<div class="col">
					<ng-container *ngFor="let item of _result?.data">
						<div class="card shadow-sm bg-light">
							<div class="card-body">
								<div class="row align-items-center">
									<div class="col">
										<div class="font-weight-bold" style="font-size: 1rem;">
											{{ item.vacancy.name }}
										</div>
										<div style="font-size: 1rem;">
											Company name
										</div>
									</div>
									<div class="col-auto">
										<button [disabled]="_loadingPage" (click)="delete(item)"
											class="table-link btn btn-sm btn-link">
											<i class="fa fa-trash" aria-hidden="true"></i>
										</button>
									</div>
								</div>
							</div>
							<div class="card-footer">
								<div class="row align-items-center">
									<div class="col">
										<span> {{ item.vacancy.state.name }}, {{ item.vacancy.city.name }} </span>
									</div>
									<div class="col-auto" *ngIf="item.applicationStatus == 0">
										<span class="text-secondary">
											{{ item.createdAt | date }}
										</span>
									</div>
								</div>
							</div>
						</div>
					</ng-container>

					<div class="col-md-12">
						<app-pagination [pagination]="_pagination" [loader]="_loadingPage"
							(_changePage)="goToPage($event)"></app-pagination>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<router-outlet></router-outlet>

<ng-container *ngIf="_showConfirm">
	<app-confirmation [_message]="_confirmMessage" [_loadingConfirm]="_loadingConfirm" (close)="closeConfirm($event)"
		(confirm)="confirm($event)">
	</app-confirmation>
</ng-container>