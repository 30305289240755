<div class="modal-backdrop" style="background-color: #0000007a;"></div>
<div class="modal modal-2 animated fadeInDown" tabindex="-1" role="dialog" style="display: block; overflow-y: auto;">
	<div class="modal-dialog modal-dialog-centered animated fadeInDown" role="document">
		<div class="modal-content">
			<div class="d-flex justify-content-center">
				<button class="btn btn-rounded btn-rounded-close" (click)="_close()">
					<i class="fa fa-times" aria-hidden="true"></i>
				</button>
			</div>

			<div class="modal-body modal-body--remove">

				<div class="row">
					<div class="col-md-12 text-center" style="font-size: 2em;">
						<i class="fa fa-trash text-danger" aria-hidden="true"></i>
					</div>
				</div>

				<div class="row">
					<div class="col-md-12 text-center">
						<!-- Desea eliminar el registro de la competencia-Habilidad seleccionada ? -->
						<span class="h5">
							{{ _message }}
						</span>
					</div>
				</div>
				<br>
				<br>

				<div class="row justify-content-md-center">
					<div class="col-md-4">
						<button type="submit" class="btn btn-block btn-outline-light" [disabled]="_loadingConfirm" (click)="_confirm()">
							<i *ngIf="_loadingConfirm" class="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i>
							Eliminar
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>