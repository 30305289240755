<div class="container" style="margin-top: 20px;min-height: 80%;">
  <ng-container *ngIf="successCase$ | async; let successCase">
    <div class="row">
      <div class="col-12">
        <youtube-player [videoId]="successCase.video" [width]="'100%'" [height]="403"></youtube-player>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <div class="row mb-5">
          <div class="col">
            <button
              class="btn btn-ok--blue"
              (click)="goBack()"
              style="color: white;"
            >
              <i class="fa fa-arrow-left" aria-hidden="true"></i>
            </button>
            &nbsp;
            <span class="h4">Casos de éxito</span>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div
              class="bg-image-responsive"
              [style.background]="
                'url(' + utils.getImgSrc(successCase.images[0]) + ')'
              "
              style="width: 15rem; height: 15rem;"
            ></div>
          </div>
        </div>

        <div class="row mt-3">
          <div class="col">
            <span class="text-dark h4">{{ successCase.name }}</span>
          </div>
        </div>

        <div class="row mt-4">
          <div class="col">
            <p>
              {{ successCase.comment }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>
