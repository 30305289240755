
<ng-container *ngIf="item">
	<div class="card " style="width:100%; height: 100%;">
		<div class="bg-image-responsive top-rounded" [style.background]="'url(' + urlImage + ')'" style="width: 100%; height: 10rem;">
		</div>
		<div class="text-center card-body card-body--grey">
			<h6 class="card-title card-title--blue">{{ item.name }}</h6>
			<h6 class="card-subtitle mb-2 card-subtitle--blue"> {{ item.company.name }} </h6>
			<h5 class="card-title card-title--blue font-weight-semi-bold">$ {{ item.minSalary | currency:'COP':'' }} - {{ item.maxSalary | currency:'COP':'' }}</h5>
		</div>
		<div class="text-center card-body">
			<!-- <pre class="card-text card-text--info text-secondary custom-font">{{ item.description | truncate: 100 }}</pre> -->
			<p class="card-text card-text--info text-secondary post-description">
				{{ item.description | truncate: 60 }}
			</p>
			<button class="btn btn-ok btn-ok--blue vacancies" (click)="goToDetail(item)">Ver más</button>
		</div>
	</div>
</ng-container>

<ng-container *ngIf="!item">
	<div class="card animated fadeIn" style="width:100%; height: 100%; opacity: 0.9;">
		<div class="bg-image-responsive bg-light text-center" style="width: 100%; height: 8em; padding-top: 2em;">
			<i class="fa fa-picture-o fa-4x ph-loading-img" aria-hidden="true"></i>
		</div>
		<div class="text-center card-body card-body--grey">
			<div class="ph-loading-sm"></div><br>
			<div class="ph-loading-xs"></div>
			<div class="ph-loading-md"></div>
		</div>
		<div class="text-center card-body">
			<div class="ph-loading"></div>
		</div>
	</div>
</ng-container>
