<div class="modal-backdrop" style="background-color: #0000007a;"></div>
<div class="modal modal-2 animated fadeInDown" tabindex="-1" role="dialog" style="display: block; overflow-y: auto;">
	<div class="modal-dialog modal-dialog-centered modal-md" role="document">
		<div class="modal-content">
			<div class="d-flex justify-content-center">
				<button class="btn btn-rounded btn-rounded-close" (click)="close()">
					<i class="fa fa-times" aria-hidden="true"></i>
				</button>
			</div>
			<div class="modal-body">
				<form [formGroup]="formRegister">
					<div class="row" style="margin-top: 10px;">
						<div class="col-12 text-center">
							<h3 class="text-secondary font-weight-bold">Regístrate</h3>
						</div>
					</div>

					<div class="row">
						<div class="col-6 form-group">
							<label for="inputEmail">Primer nombre</label>
							<input type="text" id="inputEmail" formControlName="firstName" class="form-control input-2"
								maxlength="100" (focus)="clearMessage()">
							<span class="text-danger invalid-control"
								*ngIf="formRegister.controls.firstName.invalid && formRegister.controls.firstName.dirty">
								Campo requerido.
							</span>
						</div>

						<div class="col-6 form-group">
							<label for="inputPassword">Segundo nombre</label>
							<input type="text" id="inputPassword" formControlName="secondName" maxlength="100"
								class="form-control input-2" (focus)="clearMessage()">
							<span class="text-danger invalid-control"
								*ngIf="formRegister.controls.secondName.invalid && formRegister.controls.secondName.dirty">
								Campo requerido.
							</span>
						</div>
					</div>

					<div class="row">
						<div class="col-6 form-group">
							<label for="">Primer apellido</label>
							<input type="text" class="form-control input-2" formControlName="lastName" maxlength="100">
							<span class="text-danger invalid-control"
								*ngIf="formRegister.controls.lastName.invalid && formRegister.controls.lastName.dirty">
								Campo requerido.
							</span>
						</div>
						<div class="col-6 form-group">
							<label for="">Segundo apellido</label>
							<input type="text" class="form-control input-2" formControlName="secondLastName"
								maxlength="100">
							<span class="text-danger invalid-control"
								*ngIf="formRegister.controls.secondLastName.invalid && formRegister.controls.secondLastName.dirty">
								Campo requerido.
							</span>
						</div>
					</div>

					<div class="row">
						<div class="col-12 form-group">
							<label for="">Correo electrónico</label>
							<input type="text" class="form-control input-2" formControlName="email" maxlength="60"
							[ngClass]="{'input-verified': formRegister.controls.email.valid, 'input-unavailable': formRegister.controls.email.errors && formRegister.controls.email.errors.emailTaken}">
							<span class="text-success invalid-control" *ngIf="formRegister.controls.email.valid; else invalidEmail">
								Correo disponible.
							</span>
							<ng-template #invalidEmail>
								<ng-container *ngIf="formRegister.controls.email.dirty">
									<span class="text-danger invalid-control" *ngIf="formRegister.controls.email.value == ''">
										Campo requerido.
									</span>
									<span class="text-danger invalid-control" *ngIf="formRegister.controls.email.errors && formRegister.controls.email.errors.pattern">
										Formato de correo incorrecto.
									</span>
									<span class="text-danger invalid-control" *ngIf="formRegister.controls.email.errors && formRegister.controls.email.errors.emailTaken">
										Correo no disponible.
									</span>
								</ng-container>
							</ng-template>
							<span class="text-secondary invalid-control"
								*ngIf="formRegister.controls.email.status === 'PENDING'">
								Verificando email ...
							</span>
						</div>
					</div>

					<div class="row">
						<div class="col-6 form-group">
							<label for="">Teléfono</label>
							<input type="text" class="form-control input-2" formControlName="cellphone" [patterns]="_maskPhone" mask='ABBBBBBBBBBBBBB' [validation]="false" [dropSpecialCharacters]="false" maxlength="15">
							<span class="text-danger invalid-control"
								*ngIf="formRegister.controls.cellphone.invalid && formRegister.controls.cellphone.dirty">
								Campo requerido.
							</span>
						</div>
						<div class="col-6 form-group" [ngbPopover]="popUsernameRequirements" #pUsername="ngbPopover"
						[autoClose]="false" triggers="manual">
							<label for="">Usuario</label>
							<input type="text" class="form-control input-2" formControlName="username" maxlength="60"
							(focus)="pUsername.open()" (focusout)="pUsername.close()"
							[ngClass]="{'input-verified': formRegister.controls.username.valid, 'input-unavailable': formRegister.controls.username.errors && formRegister.controls.username.errors.usernameTaken}">
							<span class="text-success invalid-control" *ngIf="formRegister.controls.username.valid; else invalidUsername">
								Usuario disponible.
							</span>
							<ng-template #invalidUsername>
								<ng-container *ngIf="formRegister.controls.username.dirty">
									<span class="text-danger invalid-control" *ngIf="formRegister.controls.username.value == ''">
										Campo requerido.
									</span>
									<span class="text-danger invalid-control" *ngIf="formRegister.controls.username.errors && formRegister.controls.username.errors.pattern">
										Formato de usuario incorrecto.
									</span>
									<span class="text-danger invalid-control" *ngIf="formRegister.controls.username.errors && formRegister.controls.username.errors.usernameTaken">
										Usuario no disponible.
									</span>
								</ng-container>
							</ng-template>
							<span class="text-secondary invalid-control"
								*ngIf="formRegister.controls.username.status === 'PENDING'">
								Verificando usuario ...
							</span>
						</div>
					</div>

					<div class="row">
						<div class="col-6 form-group" [ngbPopover]="popContent" #pPassword="ngbPopover"
							[autoClose]="false" triggers="manual">
							<label>Contraseña</label>
							<input type="password" class="form-control input-2" formControlName="password"
								(focus)="pPassword.open()" (focusout)="pPassword.close()">
							<span class="text-danger invalid-control"
								*ngIf="formRegister.controls.password.invalid && formRegister.controls.password.dirty">
								Campo requerido.
							</span>
						</div>
						<div class="col-6 form-group">
							<label for="">Confirmar contraseña</label>
							<input type="password" class="form-control input-2" formControlName="passwordConfirmation">
							<span class="text-danger"
								*ngIf="formRegister.controls.passwordConfirmation.dirty && formRegister.controls.password.value !== formRegister.controls.passwordConfirmation.value">
								<small>La contraseña no coincide</small>
							</span>
						</div>
					</div>

					<div class="row">
						<div class="col form-group">
							<div class="custom-control custom-checkbox">
								<input type="checkbox" class="custom-control-input" [(ngModel)]="_terms" [ngModelOptions]="{standalone: true}" id="customCheck1">
								<label class="custom-control-label" for="customCheck1">He leído y acepto las
									<a href="{{docsApp.usagePolicies}}" target="_BLANCK">política de uso</a> y los
									<a href="{{docsApp.terms}}" target="_BLANCK">términos y condiciones</a>
								</label>
							</div>
							<span class="text-danger" *ngIf="!_terms && _showErrorMessage">
								<small>Debe aceptar términos y condiciones para continuar</small>
							</span>
						</div>
					</div>

					<div class="row">
						<div class="col-12 form-group">
							<span class="text-danger">
								{{ errorMessage }}
							</span>
						</div>
					</div>

					<div class="row justify-content-center">
						<div class="col-5">
							<button type="submit" [disabled]="_loading" class="btn btn-block btn-ok btn-ok--blue"
								(click)="register()">
								<i *ngIf="_loading" class="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i>
								Regístrate
							</button>
						</div>
					</div>
				</form>
			</div>
			<div class="modal-body bg-light" style="padding-top: 5px;">
				<div class="row justify-content-center">
					<div class="col-auto form-group" style="font-size: 0.9rem;">
						<span class="text-secondary">¿Ya estás registrado?</span>
					</div>
				</div>

				<div class="row justify-content-center">
					<div class="col-auto">
						<a class="btn btn-outline-secondary" routerLink="../login">Inicia aquí</a>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<ng-template #popContent let-greeting="greeting">
	<app-password-requirements [password]="formRegister.controls.password.value"></app-password-requirements>
</ng-template>

<ng-template #popUsernameRequirements>
	<app-username-requirements [username]="formRegister.controls.username.value"></app-username-requirements>
</ng-template>