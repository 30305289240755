<div class="bg-oportunidapp-light-two" style="min-height: 80%;">

  <!-- Titulo del componente -->
  <div class="col-12">
    <h1 class="section-title" style="padding-top: 30px;">Cifras</h1>
  </div>
  <div class="row padding-row">
    <div class="col-4 padding-b">
      <div class="white">
        <p>
          Aplicados a Vacantes
        </p>
        <h1>{{ aplica_vacante }}</h1>
      </div>
    </div>
    <div class="col-2 padding-b">
      <div class="white">
        <p>
          Creados por empresa
        </p>
        <h1>{{ vc_creada_empresas }}</h1>
      </div>
    </div>
    <div class="col-2 padding-b">
      <div class="white">
        <p>
          Perfiles activos
        </p>
        <h1> {{ prefil_activo }} </h1>
      </div>
    </div>
    <div class="col-4 padding-b">
      <div class="white">
        <p>
          Clientes inscritos en Oportunidad
        </p>
        <h1> {{ cliente_inscritos }}</h1>
      </div>
    </div>
  </div>
  <div class="row padding-b padding-row">
    <div class="col-md-12">
      <div class="white">
        <div class="row padding-col-b">
          <div class="col-3 ">
            <div class="white">
              <p>
                Visitas mensuales
              </p>
              <h1>2 500</h1>
            </div>
          </div>

          <div class="col-3 ">
            <div class="white">
              <p>
                Vacantes activas
              </p>
              <h1>2 500</h1>
            </div>
          </div>
          <div class="col-3 ">
            <div class="white">
              <p>
                Postulantes totales
              </p>
              <h1>2 500</h1>
            </div>
          </div>
          <div class="col-3 ">
            <div class="white">
              <p>
                Postulantes totales
              </p>
              <h1>2 500</h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- 
  <div class="col-md-12" *ngIf="1===2">
    Cargando...
  </div>
-->
</div>