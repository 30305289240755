<div class="col-12 bg-footer">
	<div class="row">
		<div class="col">
			<img src="assets/footer.png" style="width: 20rem;">
		</div>

		<div class="col">
			<div class="row opp-footer-row">
				<div class="col-auto">
					
					<div>
						<a class="text-light link-footer" href="https://grupologis.co/nosotros/" target="_blank">Sobre nosotros</a>
					</div>
					<div>
						<a class="text-light link-footer" href="https://grupologis.co/atencion-a-empleados-y-clientes/" target="_blank">Contacto</a>
					</div>
					<div>
						<a class="text-light link-footer">Tiendas</a>
					</div>
				
				</div>
				<div class="col-auto opp-social-links">
					<div>
						<a href="https://www.facebook.com/GrupoLogis/" target="_blank" class="text-light link-footer">
							<i class="fa fa-facebook" aria-hidden="true"></i>
							Facebook
						</a>
					</div>
					<div>
						<a href="https://twitter.com/grupo_logis?lang=en" target="_blank" class="text-light link-footer">
							<i class="fa fa-twitter" aria-hidden="true"></i>
							Twitter
						</a>
					</div>
					<div>
						<a href="https://www.instagram.com/grupologis/" target="_blank" class="text-light link-footer">
							<i class="fa fa-instagram" aria-hidden="true"></i>
							Instagram
						</a>
					</div>
				</div>
				<div class="col-auto">
					<div>
						<span class="text-light link-footer">Calle 64 No. 50 – 03 Barranquilla - CO</span>
					</div>
					<div>
						<span class="text-light link-footer">(035)3853362 – (035)3853363</span>
					</div>
					<div>
						<span class="text-light link-footer">info@grupologis.co</span>
					</div>
				</div>
			</div>
		</div>
		<!--
		<div class="col-auto">
			<label class="text-light">Suscribete a nuestro Newsletter</label>
			<div class="input-group mb-3">
				<input type="text" class="form-control" placeholder="Correo electrónico"
					aria-describedby="button-addon2">
				<div class="input-group-append">
					<button class="btn btn-outline-secondary" type="button" id="button-addon2">Ok</button>
				</div>
			</div>
		</div>
 -->
	</div>
</div>