<card-wrapper [rounded]="0" [mpaddign]="'p-0'">
  <ng-container *ngIf="infoChat; else noSelectedChat">
    <!------------------------------------------------------------------------------------------------------------------------>
    <!------------------------------------ TITLE USER DATA ---------------------------------------------------------------------->
    <!------------------------------------------------------------------------------------------------------------------------>
    <div class="col-12" style="border-bottom: 1px solid #e5e5e5">
      <div class="row p-2">
        <div class="col-md-8 col-xs-12 col-sm-12 ">
          <div class="row mt-3">
            <div class="col-md-2 col-xs-12 col-sm-12 ">
              <img
                width="46"
                height="46"
                class="img-rounded"
                [src]="utils.getImgSrc(infoChat.image)"
                alt=""
              />
            </div>
            <div class="col-md-10 col-xs-12 col-sm-12 ">
              <p class="mt-2 title-profile">{{ infoChat.fullName }}</p>
            </div>
          </div>
        </div>
        <div class="col-md-4 col-xs-12 col-sm-12 text-right text-content">
          <p class="mt-3">
            <img width="15" src="assets/chat/solid.png" alt="" />
            Último ingreso:
            {{ infoChat.last_singin | date: "dd/MMM/yy":"en-US":"en-US" }}
          </p>
          <p>
            <img width="15" src="assets/chat/update.png" alt="" />
            Última actualización:
            {{ infoChat.updatedAt | date: "dd/MMM/yy":"en-US":"en-US" }}
          </p>
        </div>
      </div>
    </div>
    <!------------------------------------------------------------------------------------------------------------------------>
    <!------------------------------------ CHAT MESSAGES --------------------------------------------------------------------->
    <!------------------------------------------------------------------------------------------------------------------------>
    <div class="col-12">
      <div
        #scrollBar
        class="p-3 list-group list-group-flush scrollbar"
        [ngStyle]="{ height: isAdmins() ? '68vh' : '76.5vh' }"
        *ngIf="chatMessages.length; else noDataTemplate"
      >
        <ng-container
        *ngFor="let chat of chatMessages"
        >
          <div
            class="mb-3 d-flex "
            [ngClass]="infoChat.to == chat.to ? 'flex-row-reverse' : '' "
            
          >
            <ng-container>
              <img
                width="32"
                height="32"
                class="ml-2 mr-2 img-rounded"
                [src]="utils.getImgSrc(chat.image)"
                alt=""
              />
            </ng-container>
            <div class="d-flex flex-column">
              <div class="p-3" [ngClass]="messageStyle()">
                <pre class="pre-chat">{{ chat.message }}</pre>
                <ng-container *ngIf="chat.attachment">
                  <button-download-files
                    [type]="downloadType.CHAT_MESSAGES"
                    [title]="utils.getInfoS3(chat.attachment).nameFile"
                    [file]="chat.attachment"
                  >
                  </button-download-files>
                </ng-container>
              </div>
              <small>{{ utils.timeElapsed(chat.createdAt) }}</small>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
    <!------------------------------------------------------------------------------------------------------------------------>
    <!------------------------------------ SEND MESSAGE ---------------------------------------------------------------------->
    <!------------------------------------------------------------------------------------------------------------------------>
    <ng-container *ngIf="!_error">
      <div
        class="col-12"
        [ngClass]="(_error || loadingMessage) && 'itemDisabled'"
        style="border-top: 1px solid #e5e5e5"
      >
        <div class="p-2">
          <div *ngIf="messageFile && showFileName" class="row d-flex justify-content-end">
            <button class="btn input-2" type="button" (click)="onUploadDocument()">
              <div class="d-flex btn-cursor-pointer align-items-center justify-content-center download-chat-messages">
                <!-- <img class="download-icon mr-1" width="23" height="23" src="assets/download-icon-filled.png" alt="document"> -->
                <small>{{messageFile.Name}}</small>
              </div>
            </button>
          </div>
          <div class="row d-flex align-items-center">
            <div class="col-md-10 col-xs-12 col-sm-12">
              <textarea
                placeholder="Escribir mensaje..."
                class="input-search mt-1 scrollbar"
                [(ngModel)]="message"
              ></textarea>
            </div>
            <div class="col-md-2 col-xs-12 col-sm-12">
              <div class="row ">
                <div class="col-md-4 col-xs-12 col-sm-12">
                  <img
                    (click)="onUploadDocument()"
                    width="12"
                    class="btn-cursor-pointer mt-2"
                    src="assets/chat/adjunt.png"
                    alt=""
                    tooltipClass="ngTooltipTop"
                    [ngbTooltip]="messageFile.Name"
                    placement="top"
                  />
                </div>
                <div class="col-md-8 col-xs-12 col-sm-12">
                  <ng-container *ngIf="loadingMessage">
                    <i
                      class="fa fa-circle-o-notch fa-spin fa-2x"
                      aria-hidden="true"
                    ></i>
                  </ng-container>
                  <ng-container *ngIf="!loadingMessage">
                    <div
                      class="btn-cursor-pointer btn-send-message"
                      (click)="onCreateMessage()"
                    >
                      <img
                        width="23"
                        class="ml-1"
                        src="assets/chat/send.png"
                        alt=""
                      />
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>
</card-wrapper>

<ng-template #noSelectedChat>
  <div
    class="row d-flex justify-content-center text-center align-items-center"
    [ngStyle]="{ height: '87vh' }"
  >
    <div class="col-12">
      <i class="fa fa-inbox noResults fa-4x" aria-hidden="true"></i> <br />
      <h3 class=" text-secondary">
        No hay chat seleccionado
      </h3>
    </div>
  </div>
</ng-template>

<ng-template #noDataTemplate>
  <!-- IF LOADING -->
  <ng-container *ngIf="loadingPage && !_error">
    Cargando...
  </ng-container>
  <!-- IF NOT LOADING -->
  <ng-container *ngIf="!loadingPage">
    <div class="mt-5 text-center">
      <div class="col-12">
        <i class="fa fa-inbox noResults" aria-hidden="true"></i> <br />
        <!-- IF DATA NOT FOUND -->
        <ng-container *ngIf="!chatMessages?.length && !_error">
          <h4 class="h4 text-secondary">
            No se han encontrado resultados
          </h4>
        </ng-container>
        <!-- IF ERROR DATA -->
        <ng-container *ngIf="_error">
          <h4 class="h4 text-secondary">
            Error cargando el listado de chats
          </h4>
        </ng-container>
      </div>
    </div>
  </ng-container>
</ng-template>
